import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateStageFormComponent from '/src/widgets/components/pagecomponents/maintenances/CreateStageFormComponent';
import GeneralAlert from '@/widgets/alerts/general';
import  { createStageFunc }  from "@/apis/maintenances";
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';

const API_URL = import.meta.env.VITE_MAPS_KEY;
const containerStyle = {
  width: '100%',
  height: '50vh',
};

const initialCenter = {
  lat: -1.286389,
  lng: 36.817223,
};

export function CreateStagePageComponent() {
  const { id } = useParams();
  const [stage, setStage] = useState({
    name: '',
    routeId: id,
    coordinates: '',
    distance_from_source: '',
  });
  const [center, setCenter] = useState(initialCenter);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_URL,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStage({ ...stage, [name]: value });

    if (name === 'name' && value) {
      geocodeAddress(value);
    }
  };

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        setCenter({
          lat: location.lat(),
          lng: location.lng(),
        });
        setStage({
          ...stage,
          coordinates: `${location.lat()},${location.lng()}`,
        });
      } else {
        console.error(`Geocode was not successful for the following reason: ${status}`);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ status: false, message: '', type: '' });
    const token = localStorage.getItem('token');
    try {
      const createStage = await createStageFunc(token, stage);
      if (createStage) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Stage created successfully.',
          type: 'success',
        });
        setStage({
          name: '',
          routeId: id,
          coordinates: '',
        });
        setCenter(initialCenter);
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to create stage.',
          type: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'Failed to create stage.',
        type: 'error',
      });
    }
  };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex justify-between">
          <div>
            <Typography variant="h6" color="white">
              Create Route Stage
            </Typography>
          </div>
          <div className="flex items-center">
            <button onClick={() => navigate(`/dashboard/routes/${id}`)} className="text-white underline">
              Back
            </button>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {alert.status ? (
            <div className="w-1/2 mx-auto">
              <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
          ) : null}

          <CreateStageFormComponent
            formData={stage}
            loading={loading}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
          />

          {isLoaded && (
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
              {stage.coordinates && (
                <Marker
                  position={{
                    lat: parseFloat(stage.coordinates.split(',')[0]),
                    lng: parseFloat(stage.coordinates.split(',')[1]),
                  }}
                />
              )}
            </GoogleMap>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateStagePageComponent;
