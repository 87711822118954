import React from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
    Spinner
} from "@material-tailwind/react";

export function CreateCAPUserFormComponent({
    formData,
    loading,
    handleInputChange,
    handleSelectChange,
    handleSubmit
}) {
    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Username
                        </Typography>
                        <Input
                            size="lg"
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            User Email
                        </Typography>
                        <Input
                            size="lg"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>                   
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Mobile Number
                        </Typography>
                        <Input
                            size="lg"
                            name="mobileNumber"
                            value={formData.mobileNumber}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Role
                        </Typography>
                        <Select
                            size="lg"
                            name="role"
                            value={formData.role}
                            onChange={(value) => handleSelectChange('role', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        >
                            {/* Replace with actual role options */}
                            <Option value="CAPADMIN">Admin</Option>
                            <Option value="CAPAPROVER">Approver</Option>
                        </Select>                  
                    </div>  
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create User'}
                        </Button>
                    </div>
                </div>               
            </form>
        </Card>
    );
}

export default CreateCAPUserFormComponent;
