import React, { useState, useEffect } from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
    Spinner,
    Popover,
    PopoverHandler,
    PopoverContent
} from "@material-tailwind/react";

import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

export function CreateBookingFormComponent({
    clients,
    routes,
    loading,
    handleInputChange,
    handleSelectChange,
    handleSubmit
}) {
    const [selectedClient, setSelectedClient] = useState('');
    const [passengers, setPassengers] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState('');
    const [stages, setStages] = useState([]);
    const [date, setDate] = useState(null); // Changed initial date to null

    // Populate passengers based on selected client
    useEffect(() => {
        if (selectedClient) {
            const client = clients.find((c) => c.id === selectedClient);
            setPassengers(client?.users || []);
        } else {
            setPassengers([]);
        }
    }, [selectedClient, clients]);

    // Populate stages based on selected route
    useEffect(() => {
        if (selectedRoute) {
            const route = routes.find((r) => r.id === selectedRoute);
            setStages(route?.stages || []);
        } else {
            setStages([]);
        }
    }, [selectedRoute, routes]);

    // Handle form submission
    const handleFormSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        if (!selectedClient || !selectedRoute || !date || !passengers.length) {
            alert("Please fill out all required fields."); // Alert user for required fields
            return;
        }
        handleSubmit(e); // Call the parent submit handler if all required fields are filled
    };

    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleFormSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">                    
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Select Client
                        </Typography>
                        <Select
                            size="lg"
                            value={selectedClient} 
                            onChange={(value) => {
                                setSelectedClient(value);
                                handleSelectChange('clientId', value); // Update parent state
                                setSelectedRoute(''); // Reset selected route
                                setStages([]); // Clear stages when client changes
                            }}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required // Required attribute added
                        >
                            {clients?.map((client) => (
                                <Option key={client.id} value={client.id}>
                                    {client.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Select Passenger
                        </Typography>
                        <Select
                            size="lg"
                            onChange={(value) => handleSelectChange('passengerId', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required // Required attribute added
                        >
                            {passengers?.map((passenger) => (
                                <Option key={passenger.id} value={passenger.id}>
                                    {passenger.username}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Select Route
                        </Typography>
                        <Select
                            size="lg"
                            value={selectedRoute} 
                            onChange={(value) => {
                                setSelectedRoute(value);
                                handleSelectChange('routeId', value); // Update parent state
                                setStages([]); // Clear stages when route changes
                            }}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required // Required attribute added
                        >
                            {routes?.map((route) => (
                                <Option key={route.id} value={route.id}>
                                    {route.start_point} to {route.end_point}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Select Stage
                        </Typography>
                        <Select
                            size="lg"
                            onChange={(value) => handleSelectChange('stageId', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required // Required attribute added
                        >
                            {stages?.map((stage) => (
                                <Option key={stage.id} value={stage.id}>
                                    {stage.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Date
                        </Typography>

                        <Popover placement="bottom">
                            <PopoverHandler>
                                <Input
                                    label="Select a Date"
                                    name="trip_date"
                                    min={new Date()}
                                    onChange={handleInputChange}
                                    value={date ? format(date, "PPP") : ""}
                                    required // Required attribute added
                                />
                            </PopoverHandler>
                            <PopoverContent>
                                <DayPicker
                                    mode="single"
                                    selected={date}
                                    onSelect={(selectedDate) => {
                                        setDate(selectedDate);
                                        handleInputChange({ target: { name: 'trip_date', value: selectedDate } });
                                    }}
                                    showOutsideDays
                                    className="border-0"
                                    classNames={{
                                        caption: "flex justify-center py-2 mb-4 relative items-center",
                                        caption_label: "text-sm font-medium text-gray-900",
                                        nav: "flex items-center",
                                        nav_button: "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                        nav_button_previous: "absolute left-1.5",
                                        nav_button_next: "absolute right-1.5",
                                        table: "w-full border-collapse",
                                        head_row: "flex font-medium text-gray-900",
                                        head_cell: "m-0.5 w-9 font-normal text-sm",
                                        row: "flex w-full mt-2",
                                        cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                        day: "h-9 w-9 p-0 font-normal",
                                        day_range_end: "day-range-end",
                                        day_selected: "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                                        day_today: "rounded-md bg-gray-200 text-gray-900",
                                        day_outside: "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                        day_disabled: "text-gray-500 opacity-50",
                                        day_hidden: "invisible",
                                    }}
                                    components={{
                                        IconLeft: ({ ...props }) => (
                                            <ChevronLeftIcon {...props} className="h-4 w-4 stroke-2" />
                                        ),
                                        IconRight: ({ ...props }) => (
                                            <ChevronRightIcon {...props} className="h-4 w-4 stroke-2" />
                                        ),
                                    }}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Booking'}
                        </Button>
                    </div>
                </div>
            </form>
        </Card>
    );
}

export default CreateBookingFormComponent;
