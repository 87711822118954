import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateChargeFormComponent from '@/widgets/components/pagecomponents/finances/createChargeComponent';
import GeneralAlert from '@/widgets/alerts/general';
import  { createChargeFunc, fetchChargeChartData }  from "@/apis/maintenances";
import { useNavigate, useParams } from 'react-router-dom';

export function CreateClientTariffPageComponent() {
    const { id } = useParams();
    const [data, setData] = useState({
        vehicle_capacity: '',
        charge_per_km: '',
        chart_id:''
    });
    const [charges, setCharges] = useState([]);

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type: '',});  

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSelectChange = (name, value) => {
      setData({ ...data, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '',type: ''  });
        const token = localStorage.getItem('token');
        try {
          console.log("Data:", {...data, agency_id : id })
            const createCharge = await createChargeFunc(token, {...data, agency_id : id });
            if(createCharge){
              setLoading(false);
              setAlert({
                  status: true,
                  message: 'Tariff created successfully.',
                  type: 'success' 
              });
              setData({
                vehicle_capacity: '',
                charge_per_km: '',
                agency_id:id
              });
            }else{
              setLoading(false);
              setAlert({
                  status: true,
                  message: 'Tariff to create charge.',
                  type: 'error' 
              });
            }
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create Tariff.',
                type: 'error' 
            });
        }
    };

    useEffect(() => {
      const getVehicles = async () => {
        try {
          const token = localStorage.getItem('token');
          const data = await fetchChargeChartData(token);        
          setCharges(data.charges);
        } catch (error) {
          sendSentryAlert(error);
          setAlert({ status: true, message: 'Error fetching charges.', type: 'error' });
        } finally {
          setLoading(false);
        }
      };
  
      getVehicles();
    }, [])

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="flex justify-between mb-8 p-6">
          <Typography variant="h6" color="white">
                Create Tariff
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(-1)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="p-5">
            { alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }

            <CreateChargeFormComponent
                formData={data }
                loading={loading}
                charges={charges || []}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                handleSubmit={handleSubmit}
            />           
          
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateClientTariffPageComponent;
