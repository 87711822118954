import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import ClientsTableComponent from "@/widgets/components/pagecomponents/clients/organisationsTable";
import { fetchOrganisations, fetchDepartments } from "@/apis/clients";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';

export function ClientsPageComponent() {
  const [clients, setClients] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const userData = JSON.parse(localStorage.getItem("userDetails"))

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });

    const fetchData = async () => {
      try {
          const token = localStorage.getItem('token');
          const organisations = userData.roles === "CAPADMIN" ? await fetchDepartments(token, userData.agencyId) : await fetchOrganisations(token) ;
          if (organisations) {
            setClients(organisations.data);
            setStats([
              { label: 'Clients', value: organisations.statistics.totalAgencies },
              { label: 'Agencies', value: organisations.statistics.totalCAP },
            ]);
            setLoading(false);
          } else {
              setAlert({
                  status: true,
                  message: "Unable to fetch clients at this time. Try again.",
                  type: 'error',
                });
          setLoading(false);
          }
      } catch (error) {
        console.log(error)
          setLoading(false);
          sendSentryAlert(error);
          setAlert({
              status: true,
              message: 'We are having trouble fetching clients data. Try again.',
              type: 'error',
            });
      }
  };

    fetchData();
  }, []);

  const handleSettingsClick = async (userId) => {
    try {
      return true;
    } catch (error) {
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: `Failed to update user status. Please try again.`,
        type: 'error',
      });
    }
  };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            Clients
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {loading ? (
            <Typography>
              <Spinner />
            </Typography>
          ) : (
            alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : (
              <ClientsTableComponent clients={clients} statistics={stats} />
            )
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ClientsPageComponent;
