import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

import {
  NewspaperIcon,
  ChevronDownIcon,
  UserGroupIcon,
  
} from "@heroicons/react/24/solid";

 
function NavListMenu({ onReportSelect }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const navListMenuItems = [ 
    {
      title: "Bookings Report",
      icon: UserGroupIcon,
      id: "bookings_report"
    }, 
    {
      title: "Trips Report",
      icon: NewspaperIcon,
      id: "trips_report" 
    }
  ];

  const renderItems = navListMenuItems.map(({ icon, title, id }, key) => (
    <div key={key} onClick={() => onReportSelect(id)}>
      <MenuItem className="flex items-center gap-3 rounded-lg">
        <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2">
          {React.createElement(icon, {
            strokeWidth: 2,
            className: "h-6 w-6 text-gray-900",
          })}
        </div>
        <div>
          <Typography variant="h6" color="blue-gray" className="text-sm font-bold">
            {title}
          </Typography>
        </div>
      </MenuItem>
    </div>
  ));

  return (
    <React.Fragment>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} offset={{ mainAxis: 20 }} placement="bottom" allowHover={true}>
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Report Type
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""}`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""}`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-1 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

export function ReportsNavbarSection({ onReportSelect, selectedReportType }) {
  const [openNav, setOpenNav] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth >= 960 && setOpenNav(false));
  }, []);

  return (
    <Navbar className="mx-auto max-w-screen-xl px-4 py-2">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography as="a" href="#" variant="h6" className="mr-4 cursor-pointer py-1.5 lg:ml-2">
          {  selectedReportType?.id === "bookings_report" && "Bookings"} { selectedReportType?.id === "trips_report" && "Trips"} Report
        </Typography>
        <div className="hidden lg:block">
          <NavListMenu onReportSelect={onReportSelect} />
        </div>
      </div>
    </Navbar>
  );
}
