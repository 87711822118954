// apis/auth.js
import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";
const API_URL = import.meta.env.VITE_API_URL;

export async function fetchBookings(token){
    try {
        const response = await axios.get(`${API_URL}/dash/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function fetchBookingDetails(token, id){
    try {
        const response = await axios.get(`${API_URL}/dash/bookings/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function createBookingRecord(token, data){
    try {
        const response = await axios.post(`${API_URL}/dash/bookings/place`, {...data},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 201) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function filterPassengerByName(token, name){
    try {
        const response = await axios.post(`${API_URL}/dash/passengers/search`,{ name: name}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function fetchookingResorcesApiFunc(token){
    try {
        const response = await axios.get(`${API_URL}/dash/bookings/resources`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

