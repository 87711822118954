import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import BookingsTableComponent from "@/widgets/components/pagecomponents/bookings/index";
import { fetchBookings } from "@/apis/bookings";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';

export function BookingsPage() {
  const [bookings, setBookings] = useState([]);
  const [bookingStats, setBookingsStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const bookings = await fetchBookings(token);
        if (bookings) {
            setBookings(bookings?.bookings);
            setBookingsStats([
              { label: "Total", value: bookings?.bookings?.length || 0 },
              { label: "New", value: bookings?.statistics?.reserved || 0 },
              { label: "Active", value: bookings?.statistics?.active || 0 },
              { label: "Completed", value: bookings?.statistics?.completed || 0 },
              { label: "Cancelled", value: bookings?.statistics?.cancelled || 0 },
            ]);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch booking.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching booking.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if(loading){
    return (
      <Spinner />
    )
  }


  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            Bookings
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        
        {alert.status ? (
            <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
        ) : null }
            <BookingsTableComponent 
              bookings={bookings || []} 
              statistics={bookingStats || []}
            />
        </CardBody>
      </Card>
    </div>
  );
}

export default BookingsPage;
