import React from "react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";

const ClientsTableComponent = ({ clients, statistics }) => {
  const headers = ["name",  "Staff count", "Route Type","Status", "Date Created"];
  const columns = ["name", "staffcount","route_type",'is_active',"date_created"]


  return (
    <ReusableTableComponent
      headers={headers}
      columns={columns}
      data={clients}
      statistics={statistics}
      sortableColumns={[0,1,2, 3]}
      titleColumnIndex={0}
      searchEnabled={true}
      titleClickable={true}
      titleClickableUrl="/dashboard/clients"
      settingsColumn={false}
      createEntityLink="/dashboard/clients/create"
    />
  );
};

export default ClientsTableComponent;
