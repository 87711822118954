import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchRouteStages } from "@/apis/maintenances";
function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
const RouteStagesComponent = ({ id }) => {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const stage = await fetchRouteStages(token, id);
        if (stage) {
            setStages(stage);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch stops.",
            type: 'error',
          });
        }
      } catch (error) {
        console.log(error)
        setAlert({
          status: true,
          message: 'We are having trouble fetching stops.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = ["Name", "Distance from origin", "Date created"];
  const columns = ["name", "distance_from_source", "created_at"];

  const renderRow = (stage) => (
    <>
      <td>
        <Typography variant="body" color="blue-gray" className="font-semibold">
          {stage.name}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {stage.distance_from_source}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {formatDate(trip.created_at)}
        </Typography>
      </td>
    </>
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
        { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
        <ReusableTableComponent
            headers={headers}
            columns={columns}
            data={stages}
            renderRow={renderRow}
            sortableColumns={[0, 1, 2, 3, 4, 5]}
            titleColumnIndex={0}
            searchEnabled={true}
            titleClickable={true}
            titleClickableUrl="/dashboard/management/stage"
            settingsColumn={false}
            settingsColumnRedirect={false}
            createEntityLink={`/dashboard/maintenances/create-stop/${id}`}
        />
    </>
  );
};

export default RouteStagesComponent;
