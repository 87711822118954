import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ChargeChartComponent from "@/widgets/components/pagecomponents/finances/chargeChartComponent";
import FuelChargeChartComponent from "@/widgets/components/pagecomponents/finances/fuelChargeChartComponent";
 
export function ChargesComponent() {
 
  return (
    <>
      <FuelChargeChartComponent />
    </>
  );
}
export default ChargesComponent;
