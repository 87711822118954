import { useState } from "react";
import { ReportsNavbarSection } from "@/widgets/components/pagecomponents/reports/reportsNavBar";
import { TripsReportComponent } from "@/widgets/components/pagecomponents/reports/tripsReportsComponent";
import { BookingsReportComponent } from "@/widgets/components/pagecomponents/reports/bookingsReportComponent";
import { UserDispatchInvoiceReports } from "@/pages/dashboard/reports/userInvoices";

export function DispatchInvoiceReports() {
  const [reportType, setReportType] = useState([
    { name: "Bookings Reports", id: "bookings_report" },
    { name: "Trips Reports", id: "trips_report" },
  ]);

  const [selectedReportType, setSelectedReportType] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleReportTypeChange = (selectedValue) => {
    const selectedType = reportType.find((type) => type.id === selectedValue);
    console.log("selectedType", selectedType);
    setSelectedReportType(selectedType);
  };

  return (
    <div className="h-full w-full">
      {/* Navbar component with report selection handling */}
      <ReportsNavbarSection
        onReportSelect={handleReportTypeChange}
        selectedReportType={selectedReportType}
      />
      {/* Conditionally rendering the selected report */}
      {selectedReportType ? (
        selectedReportType.id === "bookings_report" ? (
          <BookingsReportComponent />
        ) : selectedReportType.id === "trips_report" ? (
          <TripsReportComponent />
        ) : null
      ) : (
        <div className="my-4 text-center text-gray-500">
          Please select a report type to view the corresponding invoices.
        </div>
      )}
    </div>
  );
}

export default DispatchInvoiceReports;
