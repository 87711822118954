import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";

import { format, subDays } from "date-fns";
import { DayPicker } from "react-day-picker";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";

const headers = [
  "Trip Date",
  "Session",
  "Passenger",
  "Index No",
  "Agency",
  "Contract Date",
  "Route",
  "Pickup/Dropoff",
  "Pickup Time",
  "Dropoff Time"
];

const columns = [
  "trip_date",
  "leg",
  "passenger",
  "indexno",
  "agency",
  "contract_date",
  "route",
  "stage",
  "pickup_time",
  "drop_off"
];

export function BookingsReportComponent() {
  const [bookingsReport, setBookingsReport] = useState([]);
  const [startDate, setStartDate] = useState(subDays(new Date(), 30)); // Default start date
  const [endDate, setEndDate] = useState(new Date()); // Default end date
  const [selectedSession, setSelectedSession] = useState(""); // Session state
  const [selectedRouteType, setSelectedRouteType] = useState(""); // Route type state
  const [selectedRoute, setSelectedRoute] = useState(""); // Route state
  const [routes, setRoutes ] = useState("");
  const [tripStats, setTripStats] = useState([
    { label: "Total trips", value: 0 },
    { label: "Total cost", value: 0 },
    { label: "Reservations", value: 0 },
    { label: "Onboard", value: 0 },
    { label: "No Show", value: 0 },
    { label: "No Booking", value: 0 },
  ]);

  // Fetch function (to be implemented by you)
  const fetchTrips = async (startDate, endDate, session, routeType, route) => {
    // Implement your API fetching logic here
    // Example: You would pass the filters as parameters to your API

    // Simulate data fetching and filtering
    const fetchedData = [
      // Example of fetched data
      {
        route: "Route 1",
        trip_date: "2024-10-01",
        leg: "morning",
        vehicle: "Vehicle A",
        driver: "Driver X",
        vehicle_capacity: 40,
        reservations: 30,
        no_shows: 5,
        on_board: 25,
        no_bookings: 0,
        distance_travelled: 50,
        cost_per_km: 2,
        total_trip_cost: 100,
      },
      // More data...
    ];

    // Filter the fetched data based on the session, route type, route, and date range
    const filteredData = fetchedData.filter((trip) => {
      const tripDate = new Date(trip.trip_date);
      return (
        (!session || trip.leg === session) &&
        (!routeType || trip.route.includes(routeType)) &&
        (!route || trip.route === route) &&
        tripDate >= startDate &&
        tripDate <= endDate
      );
    });

    setBookingsReport(filteredData);

    // Update statistics
    const totalTrips = filteredData.length;
    const totalCost = filteredData.reduce((acc, trip) => acc + trip.total_trip_cost, 0);
    const reservations = filteredData.reduce((acc, trip) => acc + trip.reservations, 0);
    const onboard = filteredData.reduce((acc, trip) => acc + trip.on_board, 0);
    const noShow = filteredData.reduce((acc, trip) => acc + trip.no_shows, 0);
    const noBooking = filteredData.reduce((acc, trip) => acc + trip.no_bookings, 0);

    setTripStats([
      { label: "Total trips", value: totalTrips },
      { label: "Total cost", value: totalCost },
      { label: "Reservations", value: reservations },
      { label: "Onboard", value: onboard },
      { label: "No Show", value: noShow },
      { label: "No Booking", value: noBooking },
    ]);
  };

  useEffect(() => {
    fetchTrips(startDate, endDate, selectedSession, selectedRouteType, selectedRoute);
  }, [startDate, endDate, selectedSession, selectedRouteType, selectedRoute]);

  return (
    <Card className="h-full w-full my-2 ">
      <section className="my-2 flex w-full justify-end px-2">
        
         {/* <div className="p-2">
          <Button variant="outlined">Filter</Button>
        </div>

        <div className="p-2">
          <Button variant="outlined" color="red">Clear</Button>
        </div> */}

        <div className="p-2 m-2">
          <Select label="Select Session" onChange={(e) => setSelectedSession(e)}>
            <Option value="morning">Morning</Option>
            <Option value="evining">Evining</Option>
          </Select>
        </div>

        <div className="p-2 m-2">
          <Select label="Select Route Type" onChange={(e) => setSelectedRouteType(e)}>
            <Option value="security">Security</Option>
            <Option value="regular">Regular</Option>
          </Select>
        </div>

        <div className="p-2 m-2">
          <Select label="Select Route" onChange={(e) => setSelectedRoute(e)}>
            {routes.length && routes.map((route)=><Option value={route.id}>{routes.name}</Option>)}
          </Select>
        </div>

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="Start Date"
                value={startDate ? format(startDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="End Date"
                value={endDate ? format(endDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={endDate}
                onSelect={setEndDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>
       
      </section>

      <div>
        <ReusableTableComponent
          headers={headers}
          columns={columns}
          data={bookingsReport || []}
          sortableColumns={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          titleColumnIndex={0}
          searchEnabled={true}
          titleClickable={false}
          settingsColumn={false}
          hideCreateButton={true}
        />
      </div>
    </Card>
  );
}

export default BookingsReportComponent;
