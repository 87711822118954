import React, { useState, useEffect } from "react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";

const BookingsTableComponent = ({ bookings, statistics }) => {
  const [filterTerms, setFilterTerms] = useState([]); 

  useEffect(() => {
    const filterAgencies = () => {
      const agencies = [
        ...new Set(bookings.map((b) => b.agency)),
      ].filter(Boolean);
      setFilterTerms(agencies); 
    }
    filterAgencies();
},[]);

  const headers = ["Passenger Name","Organisation", "From", "To","Booking Status","Session", "Route Type", "Trip Date"];
  const columns = ["passengerName","agency","startPoint","endPoint","status","leg","tripCategory", "tripDate"]

  return (
    <>
      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={bookings || []}
        statistics={statistics}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        settingsColumn={false}
        titleClickable={true}
        filterEnabled={true}
        filterLabel={"Clients"}
        filterOptions={filterTerms}
        titleClickableUrl="/dashboard/bookings"
        hideCreateButton = {false}
        createEntityLink="/dashboard/bookings/create"
      />
    </>
  );
};



export default BookingsTableComponent;
