import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import sendSentryAlert from "@/utils/sentryUtil";
import { format, subDays } from "date-fns";
import { DayPicker } from "react-day-picker";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
const socket_url = import.meta.env.VITE_TRACK_REPORTS_SOCKET_URL;

const headers = [
  "Route",
  "Trip Date",
  "Session",
  "Vehicle",
  "Driver",
  "Vehicle Capacity",
  "Reservations",
  "No Shows",
  "On Board",
  "No Bookings",
  "Distance",
  "Cost/Km",
  "Total Trip Cost",
];

const columns = [
  "route",
  "trip_date",
  "leg",
  "vehicle",
  "driver",
  "vehicle_capacity",
  "reservations",
  "no_shows",
  "on_board",
  "no_bookings",
  "distance_travelled",
  "cost_per_km",
  "total_trip_cost",
];

export function TripsReportComponent() {
  const [tripReport, setTripReport] = useState([]);
  const [startDate, setStartDate] = useState(subDays(new Date(), 30)); // Default start date
  const [endDate, setEndDate] = useState(new Date()); // Default end date
  const [selectedSession, setSelectedSession] = useState(""); // Session state
  const [selectedRouteType, setSelectedRouteType] = useState(""); // Route type state
  const [selectedRoute, setSelectedRoute] = useState(""); // Route state
  const [routes, setRoutes] = useState([]);
  const [tripStats, setTripStats] = useState([
    { label: "Total trips", value: 0 },
    { label: "Total cost", value: 0 },
    { label: "Reservations", value: 0 },
    { label: "Onboard", value: 0 },
    { label: "No Show", value: 0 },
    { label: "No Booking", value: 0 },
  ]);

  const ws = useRef(null);

  const fetchReport = () => {
    try{
      const socket = new WebSocket(socket_url);

      socket.onopen = () => {
        console.log("WebSocket connection established.");
      };

        socket.onmessage = (event) => {
          const response = JSON.parse(event.data);
          setTripReport(response.trips);

          const totalTrips = response.trips.length;
          const totalCost = response.trips.reduce((acc, trip) => acc + trip.total_trip_cost, 0);
          const reservations = response.trips.reduce((acc, trip) => acc + trip.bookings, 0);
          const onboard = response.trips.reduce((acc, trip) => acc + trip.on_board, 0);
          const noShow = response.trips.reduce((acc, trip) => acc + trip.no_shows, 0);
          const noBooking = response.trips.reduce((acc, trip) => acc + trip.no_bookings, 0);

          setTripStats([
            { label: "Total trips", value: totalTrips },
            { label: "Total cost", value: totalCost },
            { label: "Reservations", value: reservations },
            { label: "Onboard", value: onboard },
            { label: "No Show", value: noShow },
            { label: "No Booking", value: noBooking },
          ]);
        };

        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          setIsOnline(false);
        };
    
        socket.onclose = () => {
          console.log("WebSocket connection closed.");
          setIsOnline(false);
        };
    
        return () => {
          socket.close();
        };
    }catch(error){
      sendSentryAlert(error)
    }
  }

  useEffect(() => {
    fetchReport();
  }, []);

  const sendFiltersToSocket = () => {
    const filterData = {
      startDate,
      endDate,
      session: selectedSession,
      routeType: selectedRouteType,
      route: selectedRoute,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(filterData));
    }
  };

  useEffect(() => {
    sendFiltersToSocket();
  }, [startDate, endDate, selectedSession, selectedRouteType, selectedRoute]);

  return (
    <Card className="h-full w-full my-2">
      <section className="my-2 flex w-full justify-end px-2">
        <div className="p-2 m-2">
          <Select label="Select Session" onChange={(e) => setSelectedSession(e)}>
            <Option value="morning">Morning</Option>
            <Option value="evening">Evening</Option>
          </Select>
        </div>

        <div className="p-2 m-2">
          <Select label="Select Route Type" onChange={(e) => setSelectedRouteType(e)}>
            <Option value="security">Security</Option>
            <Option value="regular">Regular</Option>
          </Select>
        </div>

        <div className="p-2 m-2">
          <Select label="Select Route" onChange={(e) => setSelectedRoute(e)}>
            {routes.length && routes.map((route) => (
              <Option key={route.id} value={route.id}>{route.name}</Option>
            ))}
          </Select>
        </div>

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="Start Date"
                value={startDate ? format(startDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="p-3 m-2">
          <Popover placement="bottom">
            <PopoverHandler>
              <Input
                label="End Date"
                value={endDate ? format(endDate, "PPP") : ""}
                readOnly
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                mode="single"
                selected={endDate}
                onSelect={setEndDate}
                showOutsideDays
              />
            </PopoverContent>
          </Popover>
        </div>
      </section>

      <div>
        <ReusableTableComponent
          headers={headers}
          columns={columns}
          data={tripReport || []}
          sortableColumns={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          titleColumnIndex={0}
          statistics={tripStats}
          searchEnabled={true}
          titleClickable={false}
          settingsColumn={false}
          hideCreateButton={true}
        />
      </div>
    </Card>
  );
}

export default TripsReportComponent;
