import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import {fetchOrgAdmins}  from "@/apis/clients"
function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
const OrgAdmins = ({ id }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [filterTerms, setFilterTerms] = useState([]);

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const users = await fetchOrgAdmins(token, id);
        if (users) {
          setUsers(users) 
          const agencies = [
            ...new Set(users.map((b) => b.agency)),
          ].filter(Boolean);
          console.log(agencies)

          setFilterTerms(agencies);        
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch users.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching users.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = ["Name", "Email", "Mobile Number","Role","Agency", "Account Status", "Last Activity"];
  const columns = ["username", "email", "mobileNumber", "role","agency", "status", "lastActivity"];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={users || []}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={true}
        filterEnabled={true}
        filterLabel={"Agency"}
        filterOptions={filterTerms}
        handleSettingsEditLink={`/dashboard/users`}
        createEntityLink={`/dashboard/clients/create-user/${id}`}
      />
    </>
  );
};



export default OrgAdmins;
