import React, { useState, useEffect } from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
    Spinner
} from "@material-tailwind/react";

export function CreateClientFormComponent({
    formData,
    clients,
    loading,
    handleInputChange,
    handleSelectChange,
    handleSubmit
}) {
    const [isParentRequired, setIsParentRequired] = useState(false);
    const [isParentDisabled, setIsParentDisabled] = useState(false);

    useEffect(() => {
        if (formData.type === 'CAPACCOUNT') {
            setIsParentRequired(true);
            setIsParentDisabled(false);
        } else if (formData.type === 'SUPERCAPACCOUNT') {
            setIsParentRequired(false);
            setIsParentDisabled(true);
        } else {
            setIsParentRequired(false);
            setIsParentDisabled(false);
        }
    }, [formData.type]);

    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Business Name
                        </Typography>
                        <Input
                            size="lg"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Account Type
                        </Typography>
                        <Select
                            size="lg"
                            name="type"
                            value={formData.type}
                            onChange={(value) => handleSelectChange('type', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        >
                            <Option value="SUPERCAPACCOUNT">Client</Option>
                            <Option value="CAPACCOUNT">Agency</Option>
                        </Select>
                    </div>                   
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Select Parent Client
                        </Typography>
                        <Select
                            size="lg"
                            name="parentAgencyId"
                            onChange={(value) => handleSelectChange('parentAgencyId', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required={isParentRequired}
                            disabled={isParentDisabled}
                        >
                            {clients.map((org) => (
                                <Option key={org.id} value={org.id}>{org.name}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Route Type
                        </Typography>
                        <Select
                            size="lg"
                            name="route_type"
                            value={formData.type}
                            onChange={(value) => handleSelectChange('route_type', value)}
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        >
                            <Option value="security">Security</Option>
                            <Option value="regular">Regular</Option>
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Record'}
                        </Button>
                    </div>
                </div>
            </form>
        </Card>
    );
}

export default CreateClientFormComponent;
