import React from 'react';
import { Card } from "@material-tailwind/react";

export function BookingDetailsComponents({ bookingDetails }) {
    
    return (
        <Card color="transparent" shadow={false}>       
            <section class="bg-white py-2 antialiased">
                <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">             
                    <div class="mt-2">
                        <div class="mt-1 grow sm:mt-8 lg:mt-0">
                            <div class="space-y-2 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800">
                                <div class="space-y-2">
                                    <dl class="flex items-center justify-between gap-4">
                                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Distance traveled</dt>
                                        <dd class="text-base font-medium text-gray-900 dark:text-white">{bookingDetails?.route?.estimate_length || 0}</dd>
                                    </dl>

                                    <dl class="flex items-center justify-between gap-4">
                                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Trip Cost</dt>
                                        <dd class="text-base font-medium text-gray-500">Ksh {bookingDetails?.distance_traveled || 0}</dd>
                                    </dl>

                                    <dl class="flex items-center justify-between gap-4">
                                        <dt class="text-base font-normal text-gray-500 dark:text-gray-400">Booking Status</dt>
                                        <dd class="text-base font-medium text-green-900 ">{bookingDetails?.booking_status?.toLowerCase() || "New"}</dd>
                                    </dl>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Card>
    );
}

export default BookingDetailsComponents;
